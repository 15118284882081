import { Col, Form, Modal, Row, Tab, Table, Tabs } from "react-bootstrap";
import Draggable from "react-draggable";
import SelectAfiliado from "../afiliado/SelectAfiliado";
import { useEffect, useMemo, useRef, useState } from "react";
import { apellidosNombreFullPersona, formatMayusculaCadaPalabra, nombreApellidosFullPersona } from "../../util/customFormatter";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { realizarPaseProfesional } from "../../reducers/proyecto_slice";
import { fetchAfiliadoById } from "../../reducers/afiliado_slice";
import { fetchCuotasByAfiliado } from "../../reducers/cuota_slice";
import { useInputFile, validationFn } from "../../hooks/useInputFile";
import { BsCamera } from "react-icons/bs";
import VistaPreviaArchivo from "../UI/VistaPreviaArchivo";

const PaseProfesionalModal = ({proyecto, show, handleClose}) => {

    const dispatch = useDispatch();
    const cuotas = useSelector(state => state.cuota.cuotas);
    const [nuevoProyectista, setNuevoProyectista] = useState(null)
    const [respaldos, setRespaldos] = useState({
        valorados:[],
        foto_plano:null
    })
    const [errors, setErrors] = useState({});
   
    const [fotoCamara, setFotoCamara] = useState({
        nombreArchivo:"",
        imagenPreview:null,
        imagenVisible:[],
        depositIndex:null,
        showCameraModal:false

    });
    const [fotoPreview, setFotoPreview] = useState(null);
    const videoRef = useRef(null); 
    const {
        file: fotoPlano,
        tipo: tipoArchivoPlano,
        handleFileChange: handleFotoChange,
        resetInputFile: resetFoto,
        isValid: isFotoValid
    } = useInputFile("doc/image");
    const fileInputRef = useRef(null);


     useMemo(() => 
          { 
            const cuotasFiltradas =  cuotas?.filter(cuota => cuota.estado === 'no_pagada')
            if(nuevoProyectista) {
                if(cuotasFiltradas.length === 0) setNuevoProyectista((prev)=> ({...prev, proyectistaAportes:'sin_pendientes'})) 
                else setNuevoProyectista((prev)=> ({...prev, proyectistaAportes:'con_pendientes'})) 
            }
            return cuotasFiltradas
            }, 
          [cuotas]
        );

        useEffect(()=>{
            if(proyecto){
                const valoradosEditables = proyecto?.valorados?.map((val)=>{
                    const {ValoradoProyecto, descripcion, ...valData}=val
                    return {...ValoradoProyecto, descripcion, activo:true}
                })
                setRespaldos(prev=>({...prev, valorados: valoradosEditables
                }))
            }
        },[proyecto])

    const handleSelectAfiliado = (optionalLabel, afiliado) => {
        if(afiliado?.id) {dispatch(fetchCuotasByAfiliado(afiliado.id));
            setNuevoProyectista(afiliado)
        }
       else{ setNuevoProyectista({...afiliado, proyectistaAportes:null})}
    }

    const handleSubmit = async() => {
        const valoradosLlenados=respaldos.valorados? respaldos.valorados.every(valorado => valorado.activo? valorado.nrovalorado !== "" : true)
        :true
        let nuevosErrores = {
            plano: fotoPlano && !isFotoValid || ( !fotoPlano && !respaldos.foto_plano),
            valorados:!valoradosLlenados,
            proyectista:`${proyecto?.id_proyectista}` === `${nuevoProyectista.id}`
        };

        setErrors(nuevosErrores);
      if(!Object.values(nuevosErrores).includes(true)){
         let mensajeAlerta=`Se realizará el <strong>PASE PROFESIONAL</strong> del proyecto visado <strong>N° ${proyecto?.num_visado} </strong> al siguiente arquitecto afiliado:<br> <br>
             <ul style="list-style: none; padding: 0;">
              <li key=${nuevoProyectista.num_reg}> N° Reg.:${nuevoProyectista.num_reg}
            </li>
            <li key=${nuevoProyectista.id}1>${apellidosNombreFullPersona(nuevoProyectista?.persona, "ci")}
            </li>
               <li key=${nuevoProyectista.id}2>Sit.: ${nuevoProyectista.situacion?.nombre}
            </li>
            </ul>`
        
          const result = await Swal.fire({
              title: '¿Estás seguro?',
              html: mensajeAlerta, // Usa HTML aq
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#464646',
              confirmButtonText: 'Sí, continuar',
              cancelButtonText: 'Cancelar'
              })
            if (result.isConfirmed) {


                const formData = new FormData();
                if (fotoPlano) {
                    formData.append("foto_plano", fotoPlano);
                }

            formData.append("valorados", JSON.stringify(respaldos.valorados));
            formData.append("id_proyectista", nuevoProyectista.id);
              console.log("Pasando Profesional", proyecto)
              dispatch(realizarPaseProfesional({id:proyecto?.id, data: formData})).then((response)=>{
                console.log("REsponse Resellando", response)
                if (response.error || response.payload.error || response.payload.messages) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text:  response.payload.error || response.payload.messages[0] || 'Error al resellar el proyecto?. Intente de nuevo más tarde.',
                    });
                } else {
                  Swal.fire(`Pase Profesional Realizado con éxito `, ``, 'success');   
                  handleClose(true)
                            
                }
              })
            }}
    }  
    const startCamera = () => {

        const iniciales = proyecto?.proyectista? `${proyecto?.proyectista.persona.ci}`:"sinproyectista";
        const randomNum = Math.floor(Math.random() * 1000) + 1;
    
        setFotoCamara(prevData => ({...prevData, 
                                    nombreArchivo:`${iniciales}-${randomNum}.jpg`,
                                    showCameraModal:true
                        }))
        navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
        }).catch((err) => {
          console.error("Error al acceder a la cámara: ", err);
        });
      };
const captureImage = () => {
    if (!fotoCamara.nombreArchivo) {
      Swal.fire('Error', 'Debe proporcionar un nombre para el archivo antes de capturar la imagen.', 'error');
      return;
    }

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    const dataUrl = canvas.toDataURL('image/jpeg');
    let blob;

    try {
        blob = dataURLtoBlob(dataUrl);
        console.log("Blob creado:", blob);
    } catch (error) {
        console.error("Error al convertir el dataURL a Blob:", error.message);
    }
    
    if (blob) {
        // Solo crear el archivo si el Blob fue creado con éxito
        const file = new File([blob], fotoCamara.nombreArchivo, { type: 'image/jpeg' });
        if (fotoCamara.depositIndex === null) {
              handlePlanoChange(file, true)
          }
      
              handleFotoCameraChange("showCameraModal",false);
    } else {
        console.error("No se pudo tomar la foto.");
    }
    
   
    };

    const dataURLtoBlob = (dataURL) => {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    };
    const clearFileInput = () => {
        // Limpiar el valor del campo de archivo
        if (fileInputRef.current) {
            fileInputRef.current.value = '';  // Resetea el valor del campo
        }
    };

    const handlePlanoChange = (file, byCamera=false) => {
        handleFotoChange(file); 
        if(byCamera) clearFileInput()
        setErrors((prev)=>({...prev, plano:false}));
    }


    const handleChangeValorado = (index, value) => {
        setRespaldos(prevDatos => {
            const valorados = prevDatos.valorados?.map((val) =>
                val.id_valorado === index ? { ...val, nrovalorado: value } : val
            );
            return { ...prevDatos, valorados }; // Actualiza el estado con la nueva copia de 'valorados'
        });
        setErrors(prev => ({...prev, valorados:false }))
    };


    const handleFotoCameraChange =(name, value)=>{
        setFotoCamara(prev=>({...prev, [name]: value}))
    }
    const handleCloseThisModal = () =>{
        setNuevoProyectista(null)
        handleClose(false)
    }
   
    console.log("PaseProfesionalModal respaldos" ,proyecto,respaldos.valorados)
    
    return(
        <>   
    <Modal show={show} onHide={handleCloseThisModal} backdrop="static" 
        keyboard={false} 
        size="xl" 
        className="transparent-modal" 
        >
        <Draggable handle=".modal-header"    bounds={{top: -20}}>
        <div className={"rounded-3"} style={{border:"1px solid #9e0905"}}> 
            <Modal.Header closeButton style={{ backgroundColor: "#9e0905", color: 'white' }}>
                <Modal.Title>Pase Profesional del Visado N° {proyecto?.num_visado}</Modal.Title>
            </Modal.Header>
            <Modal.Body  style={{ backgroundColor: "#f6ecec"}}>
        
     <Row className="mb-4 row-label-strong">
        <Col md={7}>
        <Row >
                <Col xs={10} lg={5}className="mt-4">
                
                <Form.Label>Proyectista actual</Form.Label>
            
                    <div className="selected-container p-3 border rounded shadow-sm">
                    <div>
                        <strong>{`${nombreApellidosFullPersona(proyecto?.proyectista?.persona)}`}</strong>
                        <br />
                        <span>C.I.:{proyecto?.proyectista?.persona?.ci} </span>
                        <span>{proyecto?.proyectista?.situacion?.nombre}</span>
                    </div>
                    </div>
                </Col>
                <Col xs={2} lg={1} className="d-flex justify-content-center align-items-center">
                    <i className="bi bi-chevron-double-right" style={{ fontSize: '30px' }}></i>
                    </Col>

                        <Col xs={12} lg={6}>
                        <SelectAfiliado onSelect={handleSelectAfiliado} afiliado={nuevoProyectista} labelText='Nuevo Proyectista'
                            permitirEdicion={true}/>
                                 
            {nuevoProyectista?.proyectistaAportes && 
            <>
            {nuevoProyectista?.proyectistaAportes === "sin_pendientes"? <div className="alert alert-success" role="alert">
            El afiliado está habilitado para visar proyectos
            </div>
            : <>
                <div className="alert alert-danger" role="alert">
            El afiliado tiene aportes pendientes
            </div>
            </>}
            </>
            }
                       {errors.proyectista &&   
                       <div className="alert alert-danger" role="alert">
                            Debe elegir un nuevo proyectista diferente al actual.
                        </div>} 
                        </Col>
                        </Row>
                        <Row>
                        <Col xs={12} className="mt-4">  {(respaldos.valorados?.length >0) &&<> <Form.Label>VALORADOS</Form.Label>
                            <div className="rounded-2 mb-3" style={{border:"1px solid #dfdfdf", padding:"5px 8px"}}>
              
                            {respaldos.valorados?.length>0 &&
                            <>
                             <Row className="row-label-strong">
                            {respaldos.valorados?.map((val,index)=> (
                                <Col xs={6} md={12} lg={6} className="mb-3" key={`valorado_${index}`}>
                                <Form.Group>
                                    <Form.Label>{formatMayusculaCadaPalabra(val.descripcion)} N°</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={`Número de ${val.descripcion?.toLowerCase()}`}
                                        value={val.nrovalorado}
                                        onChange={(e)=>handleChangeValorado(val.id_valorado,e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                </Col>
                                
                            ))}
                            </Row>
                            {errors.valorados && <div className="alert alert-danger" role="alert">
                                    Todos los valorados utilizados en este proyecto deben estar registrados.
                            </div>}
                            </>
                        }
                        </div></>}
                        </Col>
                        </Row>
                        </Col>
                        <Col xs={12} md={5} className='col-form'>
                        <Form.Label>CARIMBO DEL PLANO</Form.Label><br></br>
                        <div className="rounded-2 mb-3" style={{border:"1px solid #dfdfdf", padding:"5px 8px"}}>

                
                 <Form.Control
                     type="file"
                     name="foto_plano"
                     isInvalid={!isFotoValid}
                     onChange={(e)=> handlePlanoChange(e.target.files[0])}
                     ref={fileInputRef} 
                 />
                   <button
                        className="icon-button"
                        style={{ marginLeft: '10px' }}
                        onClick={() => startCamera(null)}
                         type="button"
                    >
                        <BsCamera />
                    </button>
                  <Form.Control.Feedback type="invalid">
                     Por favor selecciona una imagen (.png, .jpg, .jpeg) o documento (.pdf) válido.
                  </Form.Control.Feedback>
                  <div className="d-flex justify-content-center mb-4  min-height-block">
                     <div className="text-center">
                         {(fotoPlano && isFotoValid) && (
                             <>
                                 <Form.Label>Nueva Foto del Carimbo</Form.Label>
                                 <VistaPreviaArchivo archivo={{url:URL.createObjectURL(fotoPlano), tipo:validationFn("doc/image", fotoPlano)}} /> 
                             </>
                         )}
                     </div>
                 </div>
             
                  {errors.plano && (
                                <div className="alert alert-danger" role="alert">
                                    Debe adjuntar la foto del carimbo del plano en formato imagen o pdf.
                                </div>
                                )}
                  </div>    
                  </Col>
                  </Row>
       
                    <div className="list-container-header mt-4" style={{ backgroundColor: "#f6ecec"}}>
                                <div className="left-buttons">
                                <button className="button-mine btn-cancel-outline" type="button" onClick={handleCloseThisModal}>
                                    Cancelar
                                </button>
                                </div>
                                <div className="right-buttons">
                            <button className="button-mine btn-success-outline" type="button" onClick={handleSubmit} disabled={!nuevoProyectista || nuevoProyectista.proyectistaAportes === "con_pendientes"}>
                                            <i className="bi bi-check"></i>  Realizar Pase Profesional
                                        </button>
                            
                            </div>
                            </div>
          </Modal.Body>
         </div>
       </Draggable>
        {fotoCamara.showCameraModal && (
               <Modal show={fotoCamara.showCameraModal} onHide={() => handleFotoCameraChange("showCameraModal", false)}>
                 <Modal.Header closeButton>
                   <Modal.Title>Capturar Imagen</Modal.Title>
                 </Modal.Header>
                 <Modal.Body>
                   <video ref={videoRef} style={{ width: '100%' }} />
                   <Form.Group className="mt-3">
                     <Form.Label>Nombre del Archivo</Form.Label>
                     <Form.Control
                       type="text"
                       value={fotoCamara.nombreArchivo}
                       onChange={(e) => handleFotoCameraChange("nombreArchivo",  e.target.value)}
                       placeholder="Ingrese el nombre del archivo"
                     />
                   </Form.Group>
                 </Modal.Body>
                 <Modal.Footer>
                   <button className="button-mine"  type="button" onClick={() => handleFotoCameraChange("showCameraModal", false)}>
                     Cerrar
                   </button>
                   <button className="button-mine"  type="button" onClick={captureImage}>
                     Capturar Imagen
                   </button>
                 </Modal.Footer>
               </Modal>
             )}
    </Modal>    
</>
    )

}

export default PaseProfesionalModal;