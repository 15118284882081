import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAfiliadoRequisitos, updateAfiliadoRequisito } from '../../reducers/afiliadoRequisito_slice';
import { validationFn } from '../../hooks/useInputFile';
import { fetchAfiliados, fetchInscripcionesAfiliados, modifyAfiliado, modifySituacionAfiliado } from '../../reducers/afiliado_slice';
import { UPLOADS_BASE_URL } from '../../util/axiosInstance';
import { apellidosNombreFullPersona, nombreArhivo, nombreArhivoNuevo, normalizarTexto } from '../../util/customFormatter';
import { getFileName } from '../../util/valueCalculator';

const RequisitoModal = ({ afiliado, showModal, handleClose }) => {
    const dispatch = useDispatch();
    const { afiliadoRequisitos, status, error } = useSelector(state => state.afiliadorequisito);
    const afiliadoId = afiliado?.id
    const [requisitos, setRequisitos] = useState([]);
    const [hasChanges, setHasChanges] = useState(false);
    useEffect(() => {
        if (afiliadoId && showModal) {
            dispatch(fetchAfiliadoRequisitos(afiliadoId));
        }
    }, [dispatch, afiliadoId, showModal]);

    useEffect(() => {
        if (status === 'succeeded') {
            setRequisitos(Array.isArray(afiliadoRequisitos) ? afiliadoRequisitos.map(are => ({...are, docum:null,previousDocum:are.docum, isValid:true})) : []);
        }
    }, [status, afiliadoRequisitos]);

    const handleChange = (index, field, value) => {
        const updatedRequisitos = [...requisitos];
        updatedRequisitos[index] = { ...updatedRequisitos[index], [field]: value };
        if (field === 'estado' && value === '0') {
            updatedRequisitos[index].docum = null;
        }
        setRequisitos(updatedRequisitos);
        setHasChanges(true);
    };

    const handleFileChange = (index, file) => {
        const isValid = validationFn("doc/image", file)
        console.log("FIle valid", file, isValid)
        const updatedRequisitos = [...requisitos];
        updatedRequisitos[index] = { ...updatedRequisitos[index], docum: file,  isValid:isValid };
        setRequisitos(updatedRequisitos);
        setHasChanges(true);
    };

    const handleFileRemove = (index) => {
        const updatedRequisitos = [...requisitos];
        updatedRequisitos[index].docum = null;
        updatedRequisitos[index].isValid = true;
        setRequisitos(updatedRequisitos);
        setHasChanges(true);
    };
    const obtenerFechaHoraActual = () => {
        const ahora = new Date();
        const año = ahora.getFullYear();
        const mes = String(ahora.getMonth() + 1).padStart(2, '0'); // Meses de 0-11, por eso sumamos 1
        const dia = String(ahora.getDate()).padStart(2, '0');
        const horas = String(ahora.getHours()).padStart(2, '0');
        const minutos = String(ahora.getMinutes()).padStart(2, '0');
        const segundos = String(ahora.getSeconds()).padStart(2, '0');
    
        return `${año}-${mes}-${dia} ${horas}:${minutos}:${segundos}`;
    };
    const handleSave = () => {
        requisitos.forEach(requisito => {
            const formData = new FormData();
            formData.append('estado', requisito.estado);
            formData.append('fecha_entrega', obtenerFechaHoraActual()); 
            if (requisito.docum) {
                
                const nuevoNombre = `ci${afiliado?.persona.ci}-${nombreArhivoNuevo(requisito.docum.name)}`;
                const nuevoArchivo = new File([requisito.docum], nuevoNombre, { type: requisito.docum.type });
            
                formData.append('docum', nuevoArchivo);
            }
            
            dispatch(updateAfiliadoRequisito({ id: requisito.id, data: formData })).then(data =>{
                dispatch(fetchAfiliadoRequisitos(afiliadoId));
                setHasChanges(false);
            });
        });
    };
    const handleCambioEstado = () => {
        dispatch(modifySituacionAfiliado({ id: afiliadoId, afiliado:{situacion: "preinscrito2", comentario:"Solicitud de inscripción enviada al C.A.B."} }))
        .then(data => dispatch(fetchInscripcionesAfiliados()))
        handleCloseForm();
    };
    const handleCloseForm = () => {
        setHasChanges(false);
        handleClose();
    };


    const archivosValidos = [...requisitos].every(r => r.isValid && r.isValid === true)
    const listoParaEnviar = requisitos.length>0 && requisitos.every(requisito => requisito.estado === '1');
    return (
        <Modal show={showModal} onHide={handleCloseForm} size="lg">
            <Modal.Header closeButton style={{ backgroundColor: "#255957", color: 'white' }}>
            <Modal.Title>Requisitos de inscripción</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {status === 'loading' && <p>Cargando requisitos...</p>}
                {status === 'failed' && <p>Error: {error}</p>}
                {status === 'succeeded' && requisitos.length === 0 && <p>No hay requisitos disponibles para este afiliado.</p>}
                <Form>
                    <Row className='soft-labels'>
                    <Col xs="6" md="4">
                        <Form.Label>N° PRE-INSCRIPCIÓN : </Form.Label> {afiliado?.num_preinscripcion}
                        </Col>
                        <Col xs="6" md="8">
                        <Form.Label>AFILIADO : </Form.Label> {apellidosNombreFullPersona(afiliado?.persona, "ci")}
                        </Col>
                                        </Row>
                                        <hr></hr>
                    <Row xs={2} className='soft-labels'>
                        <Form.Label>REQUISITO</Form.Label>
                        <Form.Label>RESPALDO (opcional)</Form.Label>
                    </Row>
                {archivosValidos === false && (
                <Alert variant="danger" className="mt-3">
                    <ul>
                        <p>Solo se permiten archivos .pdf o imagenes</p>
                    </ul>
                </Alert>
            )}  
      
                {status === 'succeeded' && requisitos.length > 0 && requisitos.map((requisito, index) => (
           
                        <Form.Group as={Row} key={`requisito-${requisito.id}`} style={{ borderBottom: '1px solid #ececec', marginBottom:"0.5rem" }}>
                         {afiliado?.situacion.alias==="preinscrito" &&  
                                <Col xs="1">
                                        <Form.Check 
                                            type="checkbox" 
                                            checked={requisito.estado === '1'} 
                                            onChange={(e) => handleChange(index, 'estado', e.target.checked ? '1' : '0')}
                                        />
                                    </Col>}
                                    <Col xs="4">
                                        <Form.Label>{requisito.requisito ? requisito.requisito.nombre : 'Nombre no disponible'}</Form.Label>
                                    </Col>
                       
                            {requisito.estado === '1' && (
                                <>
                                    <Col xs="1" lg="1">
                                        <label 
                                            htmlFor={`file-upload-${requisito.id}`} 
                                            className="icon-button btn-mine-primary-outline"
                                        >
                                           <i className="bi bi-upload p-2"></i>
                                        </label>
                                        <input
                                            id={`file-upload-${requisito.id}`}
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={(e) => handleFileChange(index, e.target.files[0])}
                                        />
                                    </Col>
                                    {requisito.docum && typeof requisito.docum === 'object' && (
                                    <Col lg="3" xs="2">
                                            <p title={getFileName(requisito.docum)} style={{ fontSize: '0.8rem', color: 'black', maxWidth:"100%", overflow: "hidden", textOverflow: 'ellipsis',  }}>
                                                {getFileName(requisito.docum)} 
                                                {typeof requisito.docum === 'object' && (
                                                    <Button 
                                                        title='Eliminar selección'
                                                        variant="link" 
                                                        style={{ color: 'black', textDecoration: 'none', fontSize: '0.8rem' }} 
                                                        onClick={() => handleFileRemove(index)}
                                                    >
                                                        X
                                                    </Button>
                                                )}
                                            </p>
                                            {requisitos[index].isValid === false && (
                                            <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
                                                Por favor selecciona un archivo tipo .pdf o imagen.
                                            </Form.Control.Feedback>
                                        )}
                                        </Col>
                                        )}
                                  <Col xs="3">
                                        {requisito.previousDocum && (
                                            <a  
                                                href={`${UPLOADS_BASE_URL}/${requisito.previousDocum}`} // Cambia `UPLOADS_BASE_URL` a tu URL de almacenamiento de archivos
                                                target="_blank" 
                                                rel="noopener noreferrer" 
                                                className="btn btn-link"
                                                style={{ 
                                                    fontSize: '0.8rem', 
                                                    textDecoration: 'none', 
                                                    overflow: 'hidden', 
                                                    textOverflow: 'ellipsis', 
                                                    display: 'block', 
                                                    whiteSpace: 'nowrap' // Asegura que el texto no se ajuste en varias líneas
                                                }}
                                                title={`Descargar ${getFileName(requisito.previousDocum)}`}
                                            >
                                                {getFileName(requisito.previousDocum)}
                                            </a>
                                        )}
                                    </Col>
                                    
                                </>
                            )}
                        </Form.Group>
                ))}
                     </Form>
                     <div className="d-flex justify-content-end mt-5">
                     <button className="button-mine btn-cancel" onClick={handleCloseForm}>
                    Cancelar
                </button>
                <button className="button-mine btn-mine-primary-outline" onClick={handleSave} disabled={!hasChanges || !archivosValidos}>
                    Guardar
                </button>
               {afiliado?.situacion.alias==="preinscrito" &&  
               <button className="button-mine btn-light-blue-outline" onClick={handleCambioEstado} disabled={hasChanges || !listoParaEnviar}>
                        <i className="bi bi-check-circle"></i>   Enviar al C.A.B.
                </button>}
                </div>
            </Modal.Body>
            
        </Modal>
    );
};

export default RequisitoModal;
